import { getwelcomApi as api } from "./getwelcomApi";
export const addTagTypes = ["Hoster", "Account", "User"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      hosterControllerCreate: build.mutation<
        HosterControllerCreateApiResponse,
        HosterControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster`,
          method: "POST",
          body: queryArg.createHosterDto,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerFindAll: build.query<
        HosterControllerFindAllApiResponse,
        HosterControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster`,
          params: {
            id: queryArg.id,
            shortcut: queryArg.shortcut,
            pms: queryArg.pms,
            externalId: queryArg.externalId,
            from: queryArg["from"],
            limit: queryArg.limit,
          },
        }),
        providesTags: ["Hoster"],
      }),
      hosterControllerGetDefaultProperties: build.query<
        HosterControllerGetDefaultPropertiesApiResponse,
        void
      >({
        query: () => ({ url: `/hoster/defaultproperties` }),
        providesTags: ["Hoster"],
      }),
      hosterControllerDuplicate: build.mutation<
        HosterControllerDuplicateApiResponse,
        HosterControllerDuplicateApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/duplicate`,
          method: "POST",
          body: queryArg.duplicateHosterDto,
          params: { accountId: queryArg.accountId },
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerFindOne: build.query<
        HosterControllerFindOneApiResponse,
        HosterControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/hoster/${queryArg.id}` }),
        providesTags: ["Hoster"],
      }),
      hosterControllerUpdate: build.mutation<
        HosterControllerUpdateApiResponse,
        HosterControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateHosterDto,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerDelete: build.mutation<
        HosterControllerDeleteApiResponse,
        HosterControllerDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerGetPmsIntegration: build.query<
        HosterControllerGetPmsIntegrationApiResponse,
        HosterControllerGetPmsIntegrationApiArg
      >({
        query: (queryArg) => ({ url: `/hoster/${queryArg.id}/integration` }),
        providesTags: ["Hoster"],
      }),
      hosterControllerUpdatePmsIntegration: build.mutation<
        HosterControllerUpdatePmsIntegrationApiResponse,
        HosterControllerUpdatePmsIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/integration`,
          method: "PATCH",
          body: queryArg.createPmsIntegrationDto,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerCreatePmsIntegration: build.mutation<
        HosterControllerCreatePmsIntegrationApiResponse,
        HosterControllerCreatePmsIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/integration`,
          method: "POST",
          body: queryArg.createPmsIntegrationDto,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerDeletePmsIntegration: build.mutation<
        HosterControllerDeletePmsIntegrationApiResponse,
        HosterControllerDeletePmsIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/integration`,
          method: "DELETE",
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerCreateAccomodationType: build.mutation<
        HosterControllerCreateAccomodationTypeApiResponse,
        HosterControllerCreateAccomodationTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/accomodationtype`,
          method: "POST",
          body: queryArg.createAccomodationTypeDto,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerFindAllAccomodationType: build.query<
        HosterControllerFindAllAccomodationTypeApiResponse,
        HosterControllerFindAllAccomodationTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/accomodationtype`,
        }),
        providesTags: ["Hoster"],
      }),
      hosterControllerUpdateAccomodationType: build.mutation<
        HosterControllerUpdateAccomodationTypeApiResponse,
        HosterControllerUpdateAccomodationTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/accomodationtype/${queryArg.accomodationtypeId}`,
          method: "PATCH",
          body: queryArg.updateAccomodationTypeDto,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerGetAccomodationTypeById: build.query<
        HosterControllerGetAccomodationTypeByIdApiResponse,
        HosterControllerGetAccomodationTypeByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/accomodationtype/${queryArg.accomodationtypeId}`,
        }),
        providesTags: ["Hoster"],
      }),
      hosterControllerDeleteAllAccomodationType: build.mutation<
        HosterControllerDeleteAllAccomodationTypeApiResponse,
        HosterControllerDeleteAllAccomodationTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/accomodationtype/${queryArg.accomodationtypeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerUpdateProperties: build.mutation<
        HosterControllerUpdatePropertiesApiResponse,
        HosterControllerUpdatePropertiesApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/properties`,
          method: "PATCH",
          body: queryArg.updatePropertiesDto,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerGetCrmCheckin: build.query<
        HosterControllerGetCrmCheckinApiResponse,
        HosterControllerGetCrmCheckinApiArg
      >({
        query: (queryArg) => ({ url: `/hoster/${queryArg.id}/crm-checkin` }),
        providesTags: ["Hoster"],
      }),
      hosterControllerCreateCrmCheckin: build.mutation<
        HosterControllerCreateCrmCheckinApiResponse,
        HosterControllerCreateCrmCheckinApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/crm-checkin`,
          method: "POST",
          body: queryArg.createCrmCheckin,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerUpdateCrmCheckin: build.mutation<
        HosterControllerUpdateCrmCheckinApiResponse,
        HosterControllerUpdateCrmCheckinApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/crm-checkin`,
          method: "PATCH",
          body: queryArg.updateCrmCheckin,
        }),
        invalidatesTags: ["Hoster"],
      }),
      hosterControllerDeleteCrmCheckin: build.mutation<
        HosterControllerDeleteCrmCheckinApiResponse,
        HosterControllerDeleteCrmCheckinApiArg
      >({
        query: (queryArg) => ({
          url: `/hoster/${queryArg.id}/crm-checkin`,
          method: "DELETE",
        }),
        invalidatesTags: ["Hoster"],
      }),
      hostersControllerFindAll: build.query<
        HostersControllerFindAllApiResponse,
        HostersControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/hosters`,
          params: {
            id: queryArg.id,
            shortcut: queryArg.shortcut,
            pms: queryArg.pms,
            externalId: queryArg.externalId,
            from: queryArg["from"],
            limit: queryArg.limit,
          },
        }),
        providesTags: ["Hoster"],
      }),
      accountControllerFindByHoster: build.query<
        AccountControllerFindByHosterApiResponse,
        AccountControllerFindByHosterApiArg
      >({
        query: (queryArg) => ({ url: `/account/byhoster/${queryArg.id}` }),
        providesTags: ["Account"],
      }),
      accountControllerAddHoster: build.mutation<
        AccountControllerAddHosterApiResponse,
        AccountControllerAddHosterApiArg
      >({
        query: (queryArg) => ({
          url: `/account/${queryArg.id}/addhoster/${queryArg.hosterId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["Account"],
      }),
      userControllerFindHoster: build.query<
        UserControllerFindHosterApiResponse,
        UserControllerFindHosterApiArg
      >({
        query: (queryArg) => ({
          url: `/user/${queryArg.userId}/hoster/${queryArg.hosterIndex}`,
        }),
        providesTags: ["User"],
      }),
      userControllerAddHoster: build.mutation<
        UserControllerAddHosterApiResponse,
        UserControllerAddHosterApiArg
      >({
        query: (queryArg) => ({
          url: `/user/${queryArg.id}/addhoster/${queryArg.hosterId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["User"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type HosterControllerCreateApiResponse = /** status 200  */ HosterDto;
export type HosterControllerCreateApiArg = {
  createHosterDto: CreateHosterDto;
};
export type HosterControllerFindAllApiResponse = /** status 200  */ HosterDto;
export type HosterControllerFindAllApiArg = {
  id?: string;
  shortcut?: string;
  pms?: string;
  externalId?: string;
  from?: number;
  limit?: number;
};
export type HosterControllerDuplicateApiResponse = /** status 200  */ HosterDto;
export type HosterControllerDuplicateApiArg = {
  id: string;
  accountId: string;
  duplicateHosterDto: DuplicateHosterDto;
};
export type HosterControllerFindOneApiResponse = /** status 200  */ HosterDto;
export type HosterControllerFindOneApiArg = {
  id: string;
};
export type HosterControllerUpdateApiResponse = /** status 200  */ HosterDto;
export type HosterControllerUpdateApiArg = {
  id: string;
  updateHosterDto: UpdateHosterDto;
};
export type HosterControllerDeleteApiResponse = /** status 200  */ HosterDto;
export type HosterControllerDeleteApiArg = {
  id: string;
};
export type HosterControllerGetPmsIntegrationApiResponse =
  /** status 200  */ PmsIntegrationDto;
export type HosterControllerGetPmsIntegrationApiArg = {
  id: string;
};
export type HosterControllerUpdatePmsIntegrationApiResponse =
  /** status 200  */ PmsIntegrationDto;
export type HosterControllerUpdatePmsIntegrationApiArg = {
  id: string;
  createPmsIntegrationDto: CreatePmsIntegrationDto;
};
export type HosterControllerCreatePmsIntegrationApiResponse =
  /** status 200  */ PmsIntegrationDto;
export type HosterControllerCreatePmsIntegrationApiArg = {
  id: string;
  createPmsIntegrationDto: CreatePmsIntegrationDto;
};
export type HosterControllerDeletePmsIntegrationApiResponse =
  /** status 200  */ PmsIntegrationDto;
export type HosterControllerDeletePmsIntegrationApiArg = {
  id: string;
};
export type HosterControllerCreateAccomodationTypeApiResponse =
  /** status 200  */ AccomodationTypeDto;
export type HosterControllerCreateAccomodationTypeApiArg = {
  id: string;
  createAccomodationTypeDto: CreateAccomodationTypeDto;
};
export type HosterControllerFindAllAccomodationTypeApiResponse =
  /** status 200  */ AccomodationTypeDto[];
export type HosterControllerFindAllAccomodationTypeApiArg = {
  id: string;
};
export type HosterControllerUpdateAccomodationTypeApiResponse =
  /** status 200  */ AccomodationTypeDto;
export type HosterControllerUpdateAccomodationTypeApiArg = {
  id: string;
  accomodationtypeId: string;
  updateAccomodationTypeDto: UpdateAccomodationTypeDto;
};
export type HosterControllerGetAccomodationTypeByIdApiResponse =
  /** status 200  */ AccomodationTypeDto;
export type HosterControllerGetAccomodationTypeByIdApiArg = {
  id: string;
  accomodationtypeId: string;
};
export type HosterControllerGetDefaultPropertiesApiResponse =
  /** status 200  */ { [key: string]: string };
export type HosterControllerDeleteAllAccomodationTypeApiResponse =
  /** status 200  */ HosterDto;
export type HosterControllerDeleteAllAccomodationTypeApiArg = {
  id: string;
  accomodationtypeId: string;
};
export type HosterControllerUpdatePropertiesApiResponse =
  /** status 200  */ HosterDto;
export type HosterControllerUpdatePropertiesApiArg = {
  id: string;
  updatePropertiesDto: UpdatePropertiesDto;
};
export type HosterControllerGetCrmCheckinApiResponse =
  /** status 200  */ CrmCheckinDto;
export type HosterControllerGetCrmCheckinApiArg = {
  id: string;
};
export type HosterControllerCreateCrmCheckinApiResponse =
  /** status 200  */ CrmCheckinDto;
export type HosterControllerCreateCrmCheckinApiArg = {
  id: string;
  createCrmCheckin: CreateCrmCheckin;
};
export type HosterControllerUpdateCrmCheckinApiResponse =
  /** status 200  */ CrmCheckinDto;
export type HosterControllerUpdateCrmCheckinApiArg = {
  id: string;
  updateCrmCheckin: UpdateCrmCheckin;
};
export type HosterControllerDeleteCrmCheckinApiResponse =
  /** status 200  */ CrmCheckinDto;
export type HosterControllerDeleteCrmCheckinApiArg = {
  id: string;
};
export type HostersControllerFindAllApiResponse =
  /** status 200  */ HostersDto[];
export type HostersControllerFindAllApiArg = {
  id?: string;
  shortcut?: string;
  pms?: string;
  externalId?: string;
  from?: number;
  limit?: number;
};
export type AccountControllerFindByHosterApiResponse = unknown;
export type AccountControllerFindByHosterApiArg = {
  id: string;
};
export type AccountControllerAddHosterApiResponse = unknown;
export type AccountControllerAddHosterApiArg = {
  id: string;
  hosterId: string;
};
export type UserControllerFindHosterApiResponse = unknown;
export type UserControllerFindHosterApiArg = {
  userId: string;
  hosterIndex: string;
};
export type UserControllerAddHosterApiResponse = unknown;
export type UserControllerAddHosterApiArg = {
  id: string;
  hosterId: string;
};
export type Address = {};
export type Notifications = {};
export type DirectContact = {};
export type Resources = {};
export type Customization = {};
export type Functionalities = {};
export type SchemaMixed = {};
export type PmsIntegrationDto = {
  _id: string;
  pms:
    | "medialog"
    | "chloe"
    | "misterbooking"
    | "mews"
    | "galaxy"
    | "asterio"
    | "thais";
  externalId: string;
  linkActivated: boolean;
  additionalFields: SchemaMixed;
};
export type LogosManifest = {};
export type PopupMessage = {};
export type SubscriptionStatus = {};
export type Contact = {};
export type AccomodationTypeDto = {
  _id: string;
  externalId: string;
  name: string;
  description: string;
  amenities: string[];
  bedTypes: object[];
  images: string[];
  interior: object;
  quantity: number;
  active: boolean;
};
export type PhoneDto = {};
export type ReviewInfo = {};
export type HosterDto = {
  _id: string;
  name: string;
  genericEmail: string;
  genericPhone: string;
  hostType?: string;
  currency?: string;
  dateFormat: string;
  timeFormat: string;
  language: string;
  logo: string;
  address: Address;
  notifications: Notifications;
  directcontact: DirectContact;
  resources: Resources;
  customization: Customization;
  timings: object;
  wifi: object;
  compliance: object;
  socialNetworks: object;
  functionalities: Functionalities;
  pmsIntegration: PmsIntegrationDto;
  logosManifest: LogosManifest;
  popupMessage: PopupMessage;
  subscriptionStatus: SubscriptionStatus;
  pointOfContact: Contact[];
  updatedAt: string;
  createdAt: string;
  mangopayId: string;
  slug: string;
  alentourLink: string;
  messagingLink: string;
  qrdata: string;
  status: "initializing" | "live" | "suspended" | "closed";
  isHosterMigrated: boolean;
  timeZone: string;
  analysisStartedAt: string;
  accomodationTypes: AccomodationTypeDto[];
  phonesNb: PhoneDto[];
  showEmergencyNb: boolean;
  properties: { [key: string]: string };
  reviewInfo: ReviewInfo;
  billingInfo: object;
  partnerTypes: string[];
  brand?: string;
};
export type CreateAddressDto = {
  line1?: string;
  line2?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  url?: string;
};
export type CreateNotificationsDto = {
  commentOnArticle: boolean;
  answerOnForm: boolean;
  newAnnouncements: boolean;
  productUpdates: boolean;
  ordersNotifRecipients: string[];
  emailToCustomerOnOrder: boolean;
};
export type CreateDirectContactDto = {
  lastname: string;
  firstname: string;
  email: string;
  phone: string;
};
export type RoomDetailDto = {
  label: string;
  nbAvailable: number;
};
export type ClosingPeriodDto = {
  name: string;
  beginDate: string;
  endDate: string;
};
export type CreateResourcesDto = {
  rooms: RoomDetailDto[];
  arrivalTime: number;
  departureTime: number;
  checkinTime: number;
  checkoutTime: number;
  closingPeriod: ClosingPeriodDto[];
};
export type CreateNavigationElementDto = {
  label: string;
  shortName: string;
  longName: string;
  isActivated: boolean;
  icon:
    | "cleaning"
    | "people"
    | "Calendar"
    | "activity"
    | "breakfast"
    | "balloon"
    | "bars"
    | "bed"
    | "brush"
    | "check"
    | "clock"
    | "coffee"
    | "culture"
    | "custom"
    | "doc"
    | "dooropen"
    | "entertainement"
    | "environment"
    | "chat"
    | "go"
    | "history"
    | "home"
    | "humans"
    | "infos"
    | "key"
    | "list"
    | "loop"
    | "luggage"
    | "map"
    | "music"
    | "party"
    | "phone"
    | "present"
    | "pressing"
    | "relax"
    | "schedule"
    | "secret"
    | "shopping"
    | "singlehuman"
    | "sports"
    | "spot"
    | "transports"
    | "warning"
    | "wifi"
    | "restaurant"
    | "socialMedias"
    | "service"
    | "edit"
    | "delete"
    | "";
};
export type CreateNavigationDto = {
  isNavigationBarActivated: boolean;
  navigationElements: CreateNavigationElementDto[];
};
export type CreateCustomizationDto = {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  isDarkText: boolean;
  favicon: string;
  orderableMsg: string;
  headerPhrase: string;
  welcomePicture: string;
  navigation: CreateNavigationDto;
  showLogoBackground: boolean;
};
export type CreateTimingsDto = {
  checkin: object;
  checkout: object;
  breakfastWeek?: object;
  breakfastWeekend?: object;
};
export type CreateWifiDto = {
  informations: string;
  network: string;
  password: string;
};
export type FunctionalitiesDto = {
  guestbook: boolean;
  messages: boolean;
  calls: boolean;
  chatbot: boolean;
  startingPage: "ROOMDIRECTORY" | "INTERNALSERVICES" | "EXPERIENCES";
};
export type PmsDto = {
  externalId: string;
  linkActivated: boolean;
  additionalFields?: object;
};
export type CreateIntegrationDto = {
  mews: PmsDto;
  medialog: PmsDto;
  misterbooking?: PmsDto;
  chloe?: PmsDto;
};
export type CreatePmsIntegrationDto = {
  pms:
    | "medialog"
    | "chloe"
    | "misterbooking"
    | "mews"
    | "galaxy"
    | "asterio"
    | "thais";
  externalId: string;
  linkActivated: boolean;
  additionalFields?: object;
};
export type CreateLogosDto = {
  logo192: string;
  logo512: string;
  logo16: string;
};
export type CreatePopupMessageDto = {
  showOnFirstVisit: boolean;
  message: string;
};
export type CreateSubscriptionStatusDto = {
  isGuestAppSubscribed: boolean;
  isRoomServiceSubscribed: boolean;
  isEmailSubscribed: boolean;
  isFormulaireSubscribed: boolean;
  isFacturationPMSSubscribed: boolean;
  isPremiumSubscribed: boolean;
  isOrdersSubscribed: boolean;
  isReviewsSubscribed: boolean;
  isVOIPSubscribed: boolean;
  isSmsCRMCustomerEngagementSubscribed: boolean;
  isSmsCRMCustomerPathSubscribed: boolean;
};
export type SlackContactDto = {
  urls: string[];
};
export type EmailsContactDto = {
  emails: string[];
};
export type CreateContactDto = {
  _id: string;
  name: string;
  phone: string;
  isDefault: boolean;
  contactType: string;
  contactParams: SlackContactDto | EmailsContactDto;
};
export type CreateComplianceDto = {
  inactivityPeriod?: number;
  dpoMail?: string;
  docPersonalDataProtectionPolicy?: string;
};
export type CreateSocialNetworksDto = {
  facebook?: string;
  google?: string;
  tripadvisor?: string;
  linkedIn?: string;
  youtube?: string;
  website?: string;
  instagram?: string;
  tiktok?: string;
};
export type CreateReviewInfoDto = {
  threshold: number;
  reviewLink: string;
};
export type CreateBillingInfos = {
  isAutomaticBilling: boolean;
  clientId: string;
};
export type InputField = {
  isActivated: boolean;
  isRequired: boolean;
};
export type TextField = {
  isActivated?: boolean;
  isRequired?: boolean;
  text: string;
};
export type TimeSlot = {
  start: string;
  end: string;
};
export type TimingsField = {
  isActivated?: boolean;
  isRequired?: boolean;
  Timings: TimeSlot[];
};
export type CreateCrmCheckin = {
  photo: string;
  label: string;
  description: string;
  lastName: InputField;
  firstName: InputField;
  email: InputField;
  phone: InputField;
  placeOfBirth: InputField;
  dateOfBirth: InputField;
  nationality: InputField;
  passport: InputField;
  descriptionOfPassport: TextField;
  stayDate: InputField;
  estimatedArrivalTime: TimingsField;
  personCount: InputField;
  isFirstVisit: InputField;
  accommodationPurpose: InputField;
  firstConsent: TextField;
  secondConsent: TextField;
  signature: InputField;
};
export type CreateHosterDto = {
  name: string;
  genericEmail: string;
  genericPhone: string;
  hostType: string;
  currency: string;
  dateFormat: string;
  timeFormat: string;
  timeZone: string;
  analysisStartedAt: string;
  phonesNb: string[];
  showEmergencyNb: boolean;
  language: string;
  logo: string;
  address: CreateAddressDto;
  notifications: CreateNotificationsDto;
  directcontact: CreateDirectContactDto;
  resources: CreateResourcesDto;
  customization: CreateCustomizationDto;
  timings: CreateTimingsDto;
  wifi: CreateWifiDto;
  functionalities: FunctionalitiesDto;
  integration?: CreateIntegrationDto;
  pmsIntegration?: CreatePmsIntegrationDto;
  logosManifest: CreateLogosDto;
  popupMessage: CreatePopupMessageDto;
  subscriptionStatus: CreateSubscriptionStatusDto;
  pointOfContact: CreateContactDto[];
  mangopayId: string;
  slug: string;
  compliance: CreateComplianceDto;
  socialNetworks: CreateSocialNetworksDto;
  qrdata: string;
  alentourLink: string;
  messagingLink: string;
  status: string;
  reviewInfo: CreateReviewInfoDto;
  billingInfo: CreateBillingInfos;
  checkin: CreateCrmCheckin;
  partnerTypes: string[];
};
export type DuplicateHosterDto = {
  name: string;
  address: CreateAddressDto;
  slug: string;
  billingInfo: object;
};
export type UpdateHosterDto = {};
export type CreateAccomodationTypeDto = {
  externalId: string;
  name: string;
  quantity: number;
  description?: string;
  amenities?: string[];
  interior?: object;
  bedTypes?: string[];
  images?: string[];
};
export type UpdateAccomodationTypeDto = {};
export type UpdatePropertiesDto = {
  propKey: string;
  propValue: string;
};
export type InputFieldSchema = {};
export type TextFieldSchema = {};
export type TimingsFieldSchema = {};
export type CrmCheckinDto = {
  _id: string;
  photo: string;
  label: string;
  description: string;
  lastName?: InputFieldSchema;
  firstName?: InputFieldSchema;
  email?: InputFieldSchema;
  phone?: InputFieldSchema;
  placeOfBirth?: InputFieldSchema;
  dateOfBirth?: InputFieldSchema;
  nationality?: InputFieldSchema;
  passport?: InputFieldSchema;
  descriptionOfPassport?: TextFieldSchema;
  stayDate?: InputFieldSchema;
  estimatedArrivalTime?: TimingsFieldSchema;
  personCount?: InputFieldSchema;
  isFirstVisit?: InputFieldSchema;
  accommodationPurpose?: InputFieldSchema;
  firstConsent?: TextFieldSchema;
  secondConsent?: TextFieldSchema;
  signature?: InputFieldSchema;
};
export type UpdateCrmCheckin = {
  photo?: string;
  label?: string;
  description?: string;
  lastName?: InputField;
  firstName?: InputField;
  email?: InputField;
  phone?: InputField;
  placeOfBirth?: InputField;
  dateOfBirth?: InputField;
  nationality?: InputField;
  passport?: InputField;
  descriptionOfPassport?: TextField;
  stayDate?: InputField;
  estimatedArrivalTime?: TimingsField;
  personCount?: InputField;
  isFirstVisit?: InputField;
  accommodationPurpose?: InputField;
  firstConsent?: TextField;
  secondConsent?: TextField;
  signature?: InputField;
};
export type HostersDto = {
  _id: string;
  name: string;
  address: Address;
  createdAt: string;
  slug: string;
  qrdata: string;
  timeZone: string;
};
export const {
  useHosterControllerCreateMutation,
  useHosterControllerGetDefaultPropertiesQuery,
  useHosterControllerFindAllQuery,
  useHosterControllerDuplicateMutation,
  useHosterControllerFindOneQuery,
  useHosterControllerUpdateMutation,
  useHosterControllerDeleteMutation,
  useHosterControllerGetPmsIntegrationQuery,
  useHosterControllerUpdatePmsIntegrationMutation,
  useHosterControllerCreatePmsIntegrationMutation,
  useHosterControllerDeletePmsIntegrationMutation,
  useHosterControllerCreateAccomodationTypeMutation,
  useHosterControllerFindAllAccomodationTypeQuery,
  useHosterControllerUpdateAccomodationTypeMutation,
  useHosterControllerGetAccomodationTypeByIdQuery,
  useHosterControllerDeleteAllAccomodationTypeMutation,
  useHosterControllerUpdatePropertiesMutation,
  useHosterControllerGetCrmCheckinQuery,
  useHosterControllerCreateCrmCheckinMutation,
  useHosterControllerUpdateCrmCheckinMutation,
  useHosterControllerDeleteCrmCheckinMutation,
  useHostersControllerFindAllQuery,
  useAccountControllerFindByHosterQuery,
  useAccountControllerAddHosterMutation,
  useUserControllerFindHosterQuery,
  useUserControllerAddHosterMutation,
} = injectedRtkApi;
