import {
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Switch,
  } from "@mui/material";
  import React from "react";
  import { HosterDto } from "../../reduxtk/hoster";
  
  interface HosterProps {
    hoster: Partial<HosterDto>;
    updateBrand: (brand?: BrandType) => void;
  }
  
  /*
   * BrandType
   * Format : apiname = 'displayname'
   */
  enum BrandType {
    kyriad = "Kyriad",
    kyriaddirect = "Kyriad Direct",
    kyriadprestige = "Kyriad Prestige",
    kyriadeco = "Kyriad Eco",
    campanile = "Campanile",
    campanilenature = "Campanile Nature",
    campanileprime = "Campanile Prime",
    goldentulip = "Golden Tulip",
    tulipinn = "Tulip Inn",
    tulipresidences = "Tulip Residences",
    tuliphoteletresidences = "Tulip Hotelet Residences",
    royaltulip = "Royal Tulip",
    premiereclasse = "Premiere Classe",
    hosho = "Hosho",
  }
  
  // Style for the select
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  const HosterBrand: React.FC<HosterProps> = ({ hoster, updateBrand }) => {
    const initialBrand = Object.keys(BrandType).find((key) => key === hoster.brand) as BrandType | undefined;
    const [brand, setBrand] = React.useState<BrandType | undefined>(initialBrand);
    const [isInGroup, setGroup] = React.useState<boolean>(hoster.brand !== undefined);
  
    const brandList = Object.entries(BrandType);

    const handleGroupChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setGroup(event.target.checked);
      if (!event.target.checked) {
        setBrand(undefined);
        updateBrand(undefined);
      }
    }
  
    const handleChange = (event: SelectChangeEvent<string>): void => {
      const selectedBrand = event.target.value as BrandType;
      setBrand(selectedBrand);
      updateBrand(selectedBrand);
    };
  
    return (
      <div>
        <FormControlLabel control={<Switch checked={isInGroup} onChange={handleGroupChange} />} label="Appartient au Groupe Louvre Hôtel" />
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="brand-select-label">Marque</InputLabel>
          <Select
            labelId="brand-select-label"
            id="brand-select"
            value={brand || ""}
            onChange={handleChange}
            input={<OutlinedInput label="Marque" />}
            MenuProps={MenuProps}
            disabled={!isInGroup}
          >
            {brandList.map(([key, displayname]) => (
              <MenuItem key={key} value={key}>
                {displayname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };
  
  export default HosterBrand;
  