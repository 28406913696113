import { IDetailsOffer } from "../interfaces/detailOffer";

export const subCategory = {
  smsCustomerPath: {
    id: "smsCustomerPath",
    name: "Activer les SMS dans le parcours client",
    description: "",
    active: false,
    externalId: "isSmsCRMCustomerPathSubscribed",
  },
  smsCustomerEngagement: {
    id: "smsCustomerEngagement",
    name: "Activer les SMS dans le parcours engagement client",
    description: "",
    active: false,
    externalId: "isSmsCRMCustomerEngagementSubscribed",
  },
};

export const details = {
  voip: {
    id: "voip",
    name: "Module VOIP",
    description: "",
    active: false,
    subCategories: [],
    externalId: "isVOIPSubscribed",
  },
  police_record: {
    id: "police_record",
    name: "Module Fiches de police",
    description: "",
    active: false,
    subCategories: [],
    externalId: "isPoliceRecordSubscribed",
  },
  additional_sells: {
    id: "additional_sells",
    name: "Module Vente Addionnelle",
    description: "",
    active: false,
    subCategories: [],
    externalId: "isAdditionalSellsSubscribed",
  },
  guest_app: {
    id: "guest_app",
    name: "Guest App",
    description: "",
    active: false,
    subCategories: [],
    externalId: "isGuestAppSubscribed",
  },
  crm_customer_path: {
    id: "crm_customer_path",
    name: "CRM Parcours Client",
    description: "",
    active: false,
    subCategories: [subCategory.smsCustomerPath],
    externalId: "isCRMCustomerPathSubscribed",
  },
  satisfaction_surveys: {
    id: "satisfaction_surveys",
    name: "Enquête de satisfaction",
    description: "",
    active: false,
    subCategories: [],
    externalId: "isSatisfactionSurveysSubscribed",
  },
  ereputation: {
    id: "ereputation",
    name: "Gestion de la E-réputation",
    description: "",
    subCategories: [],
    active: false,
    externalId: "isEreputationSubscribed",
  },
  crm_customer_engagement: {
    id: "crm_customer_engagement",
    name: "CRM Engagement Client",
    description: "",
    active: false,
    subCategories: [subCategory.smsCustomerEngagement],
    externalId: "isCRMCustomerEngagementSubscribed",
  },
};

export const customDetails: IDetailsOffer[] = [
  details.voip,
  details.police_record,
  details.additional_sells,
  details.guest_app,
  details.crm_customer_path,
  details.satisfaction_surveys,
  details.ereputation,
  details.crm_customer_engagement,
];

export const essentialDetails: IDetailsOffer[] = [
  details.voip,
  details.police_record,
  details.additional_sells,
  { ...details.guest_app, active: true },
  details.crm_customer_path,
  details.satisfaction_surveys,
  details.ereputation,
  details.crm_customer_engagement,
];

export const customerPathDetails: IDetailsOffer[] = [
  details.voip,
  { ...details.police_record, active: true },
  details.additional_sells,
  { ...details.guest_app, active: true },
  { ...details.crm_customer_path, active: true },
  { ...details.satisfaction_surveys, active: true },
  details.ereputation,
  details.crm_customer_engagement,
];

export const engagementCustomerDetails: IDetailsOffer[] = [
  details.voip,
  { ...details.police_record, active: true },
  details.additional_sells,
  { ...details.guest_app, active: true },
  { ...details.crm_customer_path, active: true },
  { ...details.satisfaction_surveys, active: true },
  { ...details.ereputation, active: true },
  { ...details.crm_customer_engagement, active: true },
];
