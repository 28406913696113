import { Box, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Radio, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Hoster, SubscriptionStatus, VoipParams } from '../../interfaces/Hoster';
import { IOffer } from '../../offers/interfaces/offer';
import { OfferType } from '../../offers/libs/offerType';
import { Offers } from '../../offers/types/offers.enum';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

interface OfferProps {
  hoster: Partial<Hoster>;
  onChange: (key: string, value: boolean) => void;
  items: IOffer[];
  setHoster: React.Dispatch<React.SetStateAction<Partial<Hoster>>>;
}


const Offer: React.FC<OfferProps> = (props) => {

  const onChangeVoip = (key: string = "", value: string = "") => {
    const voip : VoipParams = props.hoster.subscriptionStatus?.voipParams as VoipParams;
    const newVoipParams: VoipParams = {
      ...voip,
      [key]: value,
    };
    const newSubscriptionStatus: SubscriptionStatus = {
      ...props.hoster.subscriptionStatus as SubscriptionStatus,
      voipParams: newVoipParams,
    };
    props.setHoster({
      ...props.hoster,
      subscriptionStatus: newSubscriptionStatus,
    });
  }

  
  // Implement the Offer compnent logic here
  const [activeOffer, setActiveOffer] = useState<IOffer | null>(null);
  useEffect(() => {
    if (props.hoster && props.hoster._id && props.hoster.subscriptionStatus) {
      if (activeOffer && activeOffer.id === Offers.Custom) return;
      const offerType = OfferType.getOfferType(props.hoster.subscriptionStatus);
      const offer = props.items.find((offer) => offer.id === offerType);
      if (offer) setActiveOffer(offer);
    }
  }, [activeOffer, props.hoster, props.items]);

  const renderDetails = (offer: IOffer | null) => {
    if (!offer || !offer.details) return null;
    const filteredDetails = offer.details.filter((detail) => detail.externalId);
    const order = ["police_record", "voip"];
    filteredDetails.sort((a, b) => {
      return order.indexOf(b.id) - order.indexOf(a.id);
    });

    return (
      <>
        {filteredDetails.map((detail, index) => {
          const isChecked = (props.hoster.subscriptionStatus as any)[detail.externalId!] || false
          return (
            <Box key={detail.id} sx={{ display: 'flex', flexDirection: 'column'}}>
              <FormControlLabel
                key={detail.id}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      props.onChange(detail.externalId!, e.target.checked);
                    }}
                  />
                }
                label={detail.name}
              />
              {isChecked && detail.subCategories && detail.subCategories.map((subCategory) => {
                const control = <Switch size='small' checked={(props.hoster.subscriptionStatus as any)[subCategory.externalId!] || false} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChange(subCategory.externalId!, e.target.checked);
                }} />
                return <FormControlLabel disableTypography style={{marginLeft: "30px", fontSize: "15px"}} control={control} label={subCategory.name} />
              })}
            </Box>
          );
        })}
        {props.hoster.subscriptionStatus && (props.hoster.subscriptionStatus as any)['isVOIPSubscribed'] && (
            <>
            <Divider sx={{ mt: 5 }}>
            <Chip label="Configuration de la VoIP" icon={<PhoneInTalkIcon sx={{ ml: 1 }} />} />
            </Divider>
            <TextField
              label="Channel Url"
              variant="outlined"
              margin="normal"
              style={{ width: '175%' }}
              onChange={(e) => onChangeVoip("channelUrl", e.target.value)}
              value={(props.hoster.subscriptionStatus?.voipParams as VoipParams)?.channelUrl || ''}
            />
            <TextField
              label="partyId"
              variant="outlined"
              margin="normal"
              style={{ width: '175%' }}
              onChange={(e) => onChangeVoip("partyId", e.target.value)}
              value={(props.hoster.subscriptionStatus?.voipParams as VoipParams)?.partyId || ''}
            />
            </>
        )}
      </>
    );
  };

  const handleChangeOffer = (newOffer: Offers) => {
    const offer = props.items.find((offer) => offer.id === newOffer);
    if (!offer) return console.error(`Offer ${offer} not found`);
    setActiveOffer(offer);
    if (offer.id === Offers.Custom) return;
    const subStatus = OfferType.getSubscriptionStatusFromOfferType(
      newOffer,
      props.hoster?.subscriptionStatus
    );

    if (subStatus) {
      props.setHoster({ ...props.hoster, subscriptionStatus: subStatus });
    }
  };

  const renderOffers = (offers: IOffer[]) => {
    if (!offers) return null;
    return offers.map((offer) => {
      return (
        <FormControlLabel
          key={offer.id}
          control={
            <Radio
              checked={offer.id === activeOffer?.id}
              onChange={() => handleChangeOffer(offer.id as Offers)}
            ></Radio>
          }
          label={offer.name}
        />
      );
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          margin: '10px 20px 10px 20px',
        }}
      >
        <div>
          <span style={{ fontWeight: 'bold' }}>Offres</span>
          <FormGroup style={{ marginTop: '10px' }}>{renderOffers(props.items)} </FormGroup>
        </div>
        <div
          style={{ width: '1px', height: 'auto', background: 'grey', margin: '0px 50px 0px 50px' }}
        ></div>
        <div>
          <span style={{ fontWeight: 'bold' }}>Détails</span>
          <FormGroup style={{ marginTop: '10px' }}>{renderDetails(activeOffer)} </FormGroup>
        </div>
      </div>
    </>
  );
};

export default Offer;
